<template>
  <div
    class="row justify-center items-start bg-grey-3"
    style="min-height: calc(100vh - 83px);"
  >
    <q-card flat bordered style="width:1024px" class="q-mt-xl">
      <q-card-section class="row justify-between">
        <div class="row q-gutter-sm">
          <q-select
            :options="$LIST_JENJANG"
            v-model="selJenjang"
            outlined
            @input="(selTingkat = null), (listKelas = [])"
            label="Jenjang"
            style="min-width: 130px;"
          ></q-select>
          <q-select
            :options="selJenjang == 'MA' ? listTingkatma : listTingkatsmp"
            v-model="selTingkat"
            outlined
            @input="getListKelas()"
            label="Tingkat"
            style="min-width: 130px;"
          ></q-select>
          <q-btn
            icon="add"
            color="primary"
            label="tambah kelas"
            @click="showDialogEdit(-1)"
          ></q-btn>
        </div>

        <q-input outlined label="Cari Kelas" v-model="searchTerm">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </q-card-section>
      <q-markup-table
        flat
        bordered
        dense
        separator="horizontal"
        class="stickyTable bg-grey-4"
      >
        <thead class="bg-indigo-5 text-white text-left">
          <tr>
            <th>No</th>
            <th>Nama Kelas</th>
            <th>Tingkat</th>
            <th v-if="selJenjang == 'MA'">Peminatan</th>
            <th>Wali Kelas</th>
            <th style="width:20px;"></th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr v-for="(val, i) in filKelas" :key="i">
            <td>{{ i + 1 }}</td>
            <td>{{ val.nama }}</td>
            <td>{{ val.tingkat }}</td>
            <td v-if="selJenjang == 'MA'">{{ val.peminatan }}</td>
            <td>{{ val.nama_walas }}</td>
            <td class="q-gutter-sm">
              <q-btn
                flat
                dense
                icon="edit"
                color="secondary"
                @click="showDialogEdit(val.id)"
              ></q-btn>
              <q-btn
                flat
                dense
                color="red"
                icon="delete_forever"
                @click="
                  (toDel = { id: val.id, nama: val.nama }), (confirm = true)
                "
              />
            </td>
          </tr>
        </tbody>
      </q-markup-table>
      <q-card-section align="right">
        <q-btn outline color="primary" @click="exportExcel">export excel</q-btn>
      </q-card-section>
    </q-card>
    <q-dialog v-model="confirm" persistent>
      <q-card class="">
        <q-card-section class="row items-center">
          <strong>
            Apa anda yakin ingin menghapus Rombongan Belajar "{{ toDel.nama }}"
            ?
          </strong>
        </q-card-section>
        <q-card-section>
          <a class="q-mb-sm"
            >Tulis "{{ toDel.nama }}" tanpa tanda kutip untuk melanjutkan</a
          >
          <q-input
            outlined
            v-model="key"
            stack-label
            label="tulis disini"
          ></q-input>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="Tidak" v-close-popup />
          <q-btn
            unelevated
            label="Ya"
            color="negative"
            :disable="toDel.nama.toLowerCase() != key.toLowerCase()"
            @click="deleteData(toDel.id)"
            v-close-popup
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import DialogAkademikEditKelas from "@/components/DialogAkademikEditKelas";
import moment from "moment";

export default {
  data() {
    return {
      key: "",
      confirm: false,
      toDel: {
        id: 0,
        nama: "",
      },
      selJenjang: this.$LIST_JENJANG[0],
      listTingkatsmp: ["7", "8", "9"],
      listTingkatma: ["10", "11", "12"],
      selTingkat: null,
      listKelas: [],
      listKelasAll: [],
      searchTerm: "",
    };
  },
  computed: {
    filKelas() {
      if (this.searchTerm == "") return this.listKelas;
      else {
        return this.listKelas.filter((val) => {
          let cNama =
            val.nama.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1;
          return cNama;
        });
      }
    },
  },
  mounted() {},
  methods: {
    async exportExcel() {
      await this.$http
        .get(
          "/akademik/rombel/listall/" + this.selJenjang,
          this.$hlp.getHeader()
        )
        .then((resp) => {
          this.listKelasAll = resp.data;
        });

      let temp = this.listKelasAll.slice(0);

      var ws = this.$xlsx.utils.json_to_sheet(temp);
      var wb = this.$xlsx.utils.book_new();
      this.$xlsx.utils.book_append_sheet(wb, ws, `KELAS ${this.selJenjang}`);

      var now = moment().format("DD MMM YYYY");
      this.$xlsx.writeFile(wb, `data guru ${now}.xlsx`);
    },
    async deleteData(id) {
      await this.$http
        .put("/akademik/rombel/dropanggota/" + id)
        .then((resp) => {});
      await this.$http
        .put("/akademik/rombel/dropmapel/" + id)
        .then((resp) => {});
      await this.$http
        .put("/akademik/rombel/delkelas/" + id)
        .then(async (resp) => {
          await this.getListKelas();
        });
      this.key = "";
    },
    showDialogEdit(id) {
      this.$q
        .dialog({
          component: DialogAkademikEditKelas,
          parent: this,
          idKelas: id,
          pJenjang: this.selJenjang,
          pTingkat: this.selTingkat,
        })
        .onOk((resp) => {
          this.selJenjang = resp.jenjang;
          this.selTingkat = resp.tingkat;
          this.getListKelas();
        });
    },
    getListKelas() {
      this.$q.loading.show({
        message: "sedang mengambil data",
      });
      this.$http
        .get(
          "/akademik/rombel/list/" + this.selJenjang + "/" + this.selTingkat,
          this.$hlp.getHeader()
        )
        .then((resp) => {
          this.listKelas = resp.data;
          this.$q.loading.hide();
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
