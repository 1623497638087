<template>
  <q-dialog ref="dialog" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-card-section class="column q-col-gutter-sm">
        <q-select
          :options="$LIST_JENJANG"
          :readonly="idKelas > 0"
          v-model="bundleKelas.jenjang"
          outlined
          label="Jenjang"
          @input="getListWalas(), (bundleKelas.tingkat = null)"
        ></q-select>
        <q-select
          :options="
            bundleKelas.jenjang == 'MA' ? listTingkatma : listTingkatsmp
          "
          :readonly="idKelas > 0"
          v-model="bundleKelas.tingkat"
          outlined
          label="Tingkat"
        ></q-select>
        <q-select
          :options="listMinat"
          v-model="bundleKelas.peminatan"
          v-if="bundleKelas.jenjang == 'MA'"
          outlined
          label="Peminatan"
          style="min-width: 130px;"
        ></q-select>
        <q-input
          outlined
          v-model="bundleKelas.nama"
          @input="isNameFilled()"
          label="Nama Kelas"
        ></q-input>
        <q-select
          v-model="bundleKelas.id_wali_kelas"
          use-input
          @filter="filterFn"
          :options="filteredListGuru"
          outlined
          label="Wali Kelas"
          style="min-width: 130px;"
        ></q-select>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn flat color="primary" label="Cancel" @click="onCancelClick" />
        <q-btn
          unelevated
          v-if="idKelas < 0"
          :disable="
            isNameFilled() || !bundleKelas.tingkat || !bundleKelas.id_wali_kelas
          "
          color="primary"
          @click="createKelas"
          >Tambah</q-btn
        >
        <q-btn v-else color="primary" @click="editKelas">Edit</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: ["idKelas", "pTingkat", "pJenjang"],
  data() {
    return {
      listMinat: [null, "IPA", "IPS", "AGAMA"],
      listTingkatsmp: ["7", "8", "9"],
      listTingkatma: ["10", "11", "12"],
      listGuru: [],
      filteredListGuru: [],
      bundleKelas: {
        jenjang: this.$LIST_JENJANG[0],
        tingkat: null,
        nama: "",
        id_wali_kelas: null,
      },
    };
  },
  async mounted() {
    this.$q.loading.show({ message: "Mohon Tunggu..." });
    if (this.idKelas >= 0) {
      await this.$http
        .get("/akademik/rombel/show/" + this.idKelas, this.$hlp.getHeader())
        .then((resp) => {
          this.bundleKelas = resp.data;
        });
      await this.getListWalas();
      await this.$http
        .get(
          "/akademik/rombel/curwalas/" + this.bundleKelas.id_wali_kelas,
          this.$hlp.getHeader()
        )
        .then((resp) => {
          this.bundleKelas.id_wali_kelas = resp.data;
        });
    } else {
      this.bundleKelas.jenjang = this.pJenjang;
      this.bundleKelas.tingkat = this.pTingkat;
      await this.getListWalas();
    }
    this.$q.loading.hide();
  },
  methods: {
    isNameFilled() {
      if (this.bundleKelas.nama == "") return true;
      else return false;
    },
    filterFn(val, update) {
      if (val === "") {
        update(() => {
          this.filteredListGuru = this.listGuru;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.filteredListGuru = this.listGuru.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    },
    show() {
      this.$refs.dialog.show();
    },

    hide() {
      this.$refs.dialog.hide();
    },

    onDialogHide() {
      this.$emit("hide");
    },

    onOKClick() {
      let data = {
        jenjang: this.bundleKelas.jenjang,
        tingkat: this.bundleKelas.tingkat,
      };
      this.$emit("ok", data);
      this.hide();
    },

    async createKelas() {
      this.$q.loading.show({ message: "Menyimpan Kelas..." });
      this.bundleKelas.id_wali_kelas = this.bundleKelas.id_wali_kelas.value;
      await this.$http
        .post(
          "/akademik/rombel/tambah",
          this.bundleKelas,
          this.$hlp.getHeader()
        )
        .then((resp) => {
          this.onOKClick();
        });
      this.$q.loading.hide();
    },
    async editKelas() {
      this.$q.loading.show({ message: "Menyimpan kelas..." });
      this.bundleKelas.id_wali_kelas = this.bundleKelas.id_wali_kelas.value;
      await this.$http
        .put(
          "/akademik/rombel/edit/" + this.idKelas,
          this.bundleKelas,
          this.$hlp.getHeader()
        )
        .then((resp) => {
          this.onOKClick();
        });
      this.$q.loading.hide();
    },
    async getListWalas() {
      this.$q.loading.show({ message: "Memuat Data kelas..." });
      await this.$http
        .get(
          "/akademik/rombel/getwalas/" + this.bundleKelas.jenjang,
          this.$hlp.getHeader()
        )
        .then((resp) => {
          this.listGuru = resp.data;
        });
      this.$q.loading.hide();
    },
    onCancelClick() {
      this.hide();
    },
  },
};
</script>
